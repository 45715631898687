<!-- 影院列表-->
<template>
    <div class="root-wrap fx-column">
        <div class="header-wrap">
            <Header type="light"></Header>
            <screen-area @change="initPage"></screen-area>
        </div>
        <div class="body-wrap fx-fill" >
            <div v-infinite-scroll="loadMore" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
                <cinema-table :list="cinemaList" @click="goCinema"></cinema-table>
            </div>
            <div v-if="cinemaList.length == 0 && !loadingCinema" class="fx-middle">
                <van-empty class="custom-image" :image="require('@/assets/images/cinema_empty.png')" description="该地区暂无场次" image-size="100" />
            </div>
        </div>
    </div>
</template>

<script>
import { getCinemaList } from '@api/cinema-request'
import Header from '@/components/header';
import TabBar from '@/components/tab-bar';
import ScreenArea from '@/components/cinema/screen-area';
import CinemaTable from '@/components/cinema/cinema-table';
import { mapGetters } from 'vuex';
import { getAMapLocation, getUserLocation } from '@/libs/map-utils.js';
import pagination from '@/components/mixins/pagination';
export default {
    mixins: [pagination],
    data() {
        return {
            movieId: this.$route.query.movieId,
            movieName: this.$route.query.movieName,
            cinemaList: [],
            loadingCinema: false
        }
    },
    methods: {
        goCinema(cinemaId) {
            this.$goPage('movie-session', {
                cinemaId,
                movieId: this.movieId,
            });
        },
        loadMore() {
            if (this.pagination.next) {
                ++this.pagination.pageNo;
                this.getCinemaList();
            }
        },
        getAMapLocation() {
            getAMapLocation().then((res) => {
                this.$store.commit('setLocation', res);
            })
        },
        getCinemaList() {
            let params = {
                cityCode: this.currentCityCode,
                countyCode: this.currentCountyCode,
                countyName: this.currentCountyName,
                longitude: this.position ? this.position.lng : '',
                latitude: this.position ? this.position.lat : '',
                pageNo: this.pagination.pageNo,
            }

            if (this.currentHallCode) {
                params.specialHallId = this.currentHallCode;
            }


            this.movieId ? params.movieId = this.movieId : '';
            const loadingToast = this.$toast.loading(this.$config.loadingMsg);
            this.loadingCinema = true
            getCinemaList(params).then((res) => {
                this.cinemaList = this.cinemaList.concat(res.records);
                this.copyPagination(res);
                loadingToast.clear()
                this.loadingCinema = false
            }).finally(() => this.loadingCinema = false)
        },
        initPage() {
            this.getDefaultPagination();
            this.cinemaList = [];
            this.getCinemaList();
            if (this.movieName) {
                this.$documentTitle(this.movieName);
            }
        }
    },
    mounted() {
        this.$store.commit('setSelectedCounty', null);
        this.initPage();
        getUserLocation().then((change) => {
            if (change) this.initPage();
        });

    },
    computed: {
        ...mapGetters([
            'currentCityCode',
            'position',
            'currentCountyCode',
            'currentCountyName',
            'currentHallCode',
        ])
    },
    components: {
        Header,
        TabBar,
        ScreenArea,
        CinemaTable
    }
}
</script>

<style lang="scss" scoped>
.header-wrap {
}
.body-wrap {
	position: relative;
	> div {
		position: absolute;
		width: 100%;
		top: 0;
		bottom: 0;
		overflow-y: scroll;
	}
}
// .footer-wrap {
//     padding-top: 50px;
// }
</style>
